<template>
  <v-text-field
    rounded
    outlined
    dense
    label="Search"
    :prepend-inner-icon="icons.mdiMagnify"
    class="app-bar-search flex-grow-0"
    hide-details
    :value="value"
    @input="$emit('input', $event)"
  ></v-text-field>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      content: this.value,
      icons: {
        mdiMagnify,
      },
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.content)
    },
  },
}
</script>
